import React from "react";
import Header from "./Header";

function Invoice() {
  return (
    <div className="m-5">
      <Header />
    </div>
  );
}
export default Invoice;
