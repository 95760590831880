import Invoice from "../src/components/Invoice";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './index.css'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:id/" element={<Invoice />} />
      </Routes>
    </Router>
  );
}

export default App;
