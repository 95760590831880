import React, { useCallback, useEffect, useMemo, useState } from "react";
import Ohminvoicelogo from "./Ohminvoicelogo";
import axios from "axios";
import GenericPdfDownloader from "./GenericPDFComponent";
import { Loader } from "react-clip-loader";
import "react-clip-loader/dist/index.css";

function Header() {
  // eslint-disable-next-line
  const tripid = useMemo(
    () => window.location.href.split("/").reverse()?.[0],
    []
  );

  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [invoiceString, setInvoiceString] = useState("")

  const getData = useCallback(async () => {
    setLoading(true);
    if (tripid) {
      const payload = {
        trip_id: tripid,
      };

      const resp = await axios.post(
        "https://ohmelogistics.com/user_invoice",
        payload
      );
      if (resp?.success === false) {
        alert("DATA NOT FOUND");
        console.log("hello");
      } else {
        setData(resp?.data);
        setLoading(false);


        // Extracting date and month in digit format
        const completeDate = new Date(resp.data?.detail?.complete_date_tag);
        setDate("0" + completeDate.getDate().toString().slice(-2));
        setMonth("0" + (completeDate.getMonth() + 1).toString().slice(-2));
        setYear(completeDate.getFullYear().toString());


  const string = `${completeDate.getDate().toString().slice(-2)}_${(completeDate.getMonth() + 1).toString().slice(-2)}_${completeDate.getFullYear().toString()}_${resp?.data?.detail?.unique_id
    .toString()
    .padStart(7, "0")}`;

    setInvoiceString(string)



      }
    }
  }, [tripid]);

  useEffect(() => {
    getData();
  }, []);



  // const invoiceString = "String"

  return (
    <>
      <div>
        {loading ? (
          <Loader large={true} color="red" secondaryColor="black" />
        ) : (
          <div>
            <div id="invoice">
              <div className="m-10 mt-10">
                <div className="flex justify-between">
                  <Ohminvoicelogo />
                  <div>
                    <div>
                      {data ? (
                        <p className="text-xl">
                          {data?.detail?.invoice_number || (
                            <p className="text-xl">
                              OHM PTE {date}
                              {month}
                              {year}{" "}
                              {data?.detail?.unique_id
                                .toString()
                                .padStart(7, "0")}
                            </p>
                          )}
                        </p>
                      ) : (
                        <p className="text-xl">
                          OHM PTE {date}
                          {month}
                          {year}{" "}
                          {data?.detail?.unique_id.toString().padStart(7, "0")}
                        </p>
                      )}
                    </div>
                    <p className="text-green-600 text-4xl text-right">
                      Tax Invoice
                    </p>
                    <p className="text-gray-600 mt-2 text-xl text-right">
                      Save Environment
                    </p>
                  </div>
                </div>
                <div className="pl-2 pt-4">
                  <p>1,22B,Administrative Staff College Of India Campus,</p>
                  <p>Road Number 5,Banjara Hills,</p>
                  <p>Hyderabad,Telangana-500034</p>
                </div>
                <div className="pt-3">
                  <p className="">
                    Service Tax Category: Passenger Transportation
                  </p>
                </div>
                <div>
                  <div className="flex justify-between">
                    <p className="">GSTIN/UIN: 36AADCO2887K1ZY</p>
                    <div>
                      <p className="">HSN Code: 996601</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <hr className="border border-black h-px w-screen" />
              </div>
              <div className="pv-8 flex justify-between">
                <div>
                  <p className="w-60 pl-10 pt-2">
                    {data?.detail?.source_address}
                  </p>
                  <img
                    src="assets/gren-location-icon.svg"
                    alt="location_icon"
                    className="p-10"
                  />
                </div>
                <div className="rotate-90">
                  <img
                    src="assets/Group_60.svg"
                    alt="Group"
                    className="pt-20"
                  />
                </div>
                <div>
                  <p className="w-60 pl-10 pt-2">
                    {data?.detail?.destination_address}
                  </p>
                  <img
                    src="assets/red-location-icon.svg"
                    alt="location_icon"
                    className="p-10"
                  />
                </div>
              </div>
              <div>
                <hr className="border border-black w-screen" />
              </div>
              {data?.detail?.corporate_id !== null ? (
                <div className="container">
                  <table className="table-auto  m-2 mx-auto w-screen">
                    <tr>
                      <td rowSpan={2} className="bg-black w-40">
                        <img src="assets/user.svg" alt="user" className="p-4" />
                      </td>
                      <td rowSpan={2} className="bg-black w-40">
                        <div>
                          <span className="text-gray-400  text-xl">
                            Customer Name
                          </span>
                          <br />
                          <span className=" text-white text-xl">
                            {data?.detail?.user_first_name}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center text-xl text-gray-100 bg-green-900 pb-8">
                        <p className="text-center border-rounded underline font-semibold ">
                          CORPORATE INVOICE BILL
                        </p>
                        {/* Distance price */}
                      </td>
                      <td className="pl-10  bg-green-900 pb-2">
                        {/* {data?.detail?.distance_cost} */}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} className="bg-black w-40">
                        <img src="assets/pin.svg" alt="pin" className="p-4" />
                      </td>
                      <td rowSpan={2} className="bg-black w-40">
                        <div>
                          <span className="text-gray-400 text-xl">TRIP ID</span>
                          <br />
                          <span className=" text-gray-100 text-xl">
                            {data?.detail?.unique_id}
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className=" text-xl  bg-green-900 pb-2">
                        {/* <p className="text-center border-rounded underline font-semibold ">
                          INVOICE BILL
                        </p> */}
                      </td>
                      <td className="pl-10 text-xl bg-green-900 pb-2">
                        {/* {data?.detail?.surge_fee} */}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} className="bg-black w-40">
                        <img
                          src="assets/vehicle-number.svg"
                          alt="vehicle"
                          className="p-4"
                        />
                      </td>
                      <td rowSpan={2} className="bg-black w-40">
                        <div>
                          <span className="text-gray-400  text-xl">
                            Vehicle Number
                          </span>
                          <br />
                          <span className=" text-gray-100 text-xl">
                            {
                              data?.provider_detail?.vehicle_detail?.[0]
                                .plate_no
                            }
                          </span>
                        </div>
                      </td>

                      <td className="pl-10 text-xl bg-green-900 pb-2">
                        {/* Amount */}
                      </td>
                      <td className="pl-10 text-xl bg-green-900 pb-2">
                        {/* {data?.detail?.base_distance_cost.toFixed(2)}  */}
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-28 text-xl  bg-green-900 pb-2">
                        {/* Toll/Parking charges reimbursed */}
                      </td>
                      <td className="pl-10 text-xl  bg-green-900 pb-2">
                        {/* {data?.detail?.toll_amount} */}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} className="bg-black w-40">
                        <img
                          src="assets/vehicle-type.svg"
                          alt=""
                          className="p-4"
                        />
                      </td>
                      <td rowSpan={2} className="bg-black w-40">
                        <div>
                          <span className="text-gray-400  text-xl">
                            Vehicle Type
                          </span>
                          <br />
                          <span className=" text-gray-100 text-xl">
                            {data?.service_detail?.typename}
                          </span>
                        </div>
                      </td>
                      <td className="pl-10 text-xl  bg-green-900 pb-2">
                        {/* Tax */}
                      </td>
                      <td className="pl-10 text-xl  bg-green-900 pb-2">
                        {/* {data?.detail?.tax_fee.toFixed(2)} */}
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-28 text-xl  bg-green-900 pb-2">
                        {/* Discount */}
                      </td>
                      <td className="pl-10 text-xl  bg-green-900 pb-2">
                        {/* {data?.detail?.promo_payment}{" "} */}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} className="bg-black w-40">
                        <img
                          src="assets/mobile.svg"
                          alt="mobile"
                          className="p-4"
                        />
                      </td>
                      <td rowSpan={2} className="bg-black w-40">
                        <div>
                          <span className="text-gray-400 text-xl">
                            Customer Ph.No
                          </span>
                          <br />
                          <span className="text-gray-100 text-xl">
                            {data?.user_detail?.phone}
                          </span>
                        </div>
                      </td>
                      <td className="pl-28 text-xl text-gray-100 bg-green-600 pb-2">
                        {/* Airport parking charges */}
                      </td>
                      <td className="pl-10 text-xl text-gray-100 bg-teal-700 pb-2">
                        {/* {data?.detail?.airport_add_charge} */}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-center text-xl text-gray-100 bg-green-600">
                        Total amount
                      </td>
                      <td className=" text-center text-xl text-gray-100 bg-teal-700">
                        {/* {data?.detail?.total} */} 0
                      </td>
                    </tr>
                  </table>
                </div>
              ) : (
                <>
                  {data?.detail?.custom_price === true &&
                    !data?.detail?.car_rental_id ? (
                    <div className="container">
                      <table className="table-auto  m-2 mx-auto w-screen">
                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/user.svg"
                              alt="user"
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400  text-xl">
                                Customer Name
                              </span>
                              <br />
                              <span className=" text-white text-xl">
                                {data?.detail?.user_first_name}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center text-xl text-gray-100 bg-green-900 pb-8">
                            <p className="text-center border-rounded underline font-semibold ">
                              INVOICE BILL
                            </p>
                            {/* Distance price */}
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {/* {data?.detail?.distance_cost} */}
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/pin.svg"
                              alt="pin"
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400 text-xl">
                                TRIP ID
                              </span>
                              <br />
                              <span className=" text-gray-100 text-xl">
                                {data?.detail?.unique_id}
                              </span>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className=" text-xl text-gray-100 bg-green-900 pb-2">
                            {/* <p className="text-center border-rounded underline font-semibold ">
                                            INVOICE BILL
                                          </p> */}
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {/* {data?.detail?.surge_fee} */}
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/vehicle-number.svg"
                              alt="vehicle"
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400  text-xl">
                                Vehicle Number
                              </span>
                              <br />
                              <span className=" text-gray-100 text-xl">
                                {
                                  data?.provider_detail?.vehicle_detail?.[0]
                                    .plate_no
                                }
                              </span>
                            </div>
                          </td>

                          <td className="text-center text-xl text-gray-100 bg-green-600 pb-2">
                            Amount
                          </td>
                          <td className="text-center text-xl text-white bg-teal-700 pb-2">
                            {data?.detail?.base_distance_cost.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-28 text-xl text-gray-100 bg-green-900 pb-2">
                            {/* Toll/Parking charges reimbursed */}
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {/* {data?.detail?.toll_amount} */}
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/vehicle-type.svg"
                              alt=""
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400  text-xl">
                                Vehicle Type
                              </span>
                              <br />
                              <span className=" text-gray-100 text-xl">
                                {data?.service_detail?.typename}
                              </span>
                            </div>
                          </td>
                          <td className="text-center text-xl text-gray-100 bg-green-600 pb-2">
                            Tax
                          </td>
                          <td className="text-center text-xl text-gray-100 bg-teal-700 pb-2">
                            {data?.detail?.tax_fee.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-28 text-xl text-gray-100 bg-green-900 pb-2">
                            {/* Discount */}
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {/* {data?.detail?.promo_payment}{" "} */}
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/mobile.svg"
                              alt="mobile"
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400 text-xl">
                                Customer Ph.No
                              </span>
                              <br />
                              <span className="text-gray-100 text-xl">
                                {data?.user_detail?.phone}
                              </span>
                            </div>
                          </td>
                          <td className="pl-28 text-xl text-gray-100 bg-green-600 pb-2">
                            {/* Airport parking charges */}
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-teal-700 pb-2">
                            {/* {data?.detail?.airport_add_charge} */}
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center text-xl text-gray-100 bg-green-600">
                            Total amount
                          </td>
                          <td className=" text-center text-xl text-gray-100 bg-teal-700">
                            {data?.detail?.total}
                          </td>
                        </tr>
                      </table>
                    </div>
                  ) : (
                    <div className="container">
                      <table className="table-auto  m-2 mx-auto w-screen">
                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/user.svg"
                              alt="user"
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400  text-xl">
                                Customer Name
                              </span>
                              <br />
                              <span className="pl-4 text-white text-xl">
                                {data?.detail?.user_first_name}
                              </span>
                            </div>
                          </td>
                          <td className="pl-28 text-xl text-gray-100 bg-green-500 pb-2">
                            Base price - &nbsp;
                            {data?.detail?.car_rental_id &&
                              data?.detail?.car_rental_id !== ""
                              ? data?.rental_package?.typename
                              : ""}
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-500 pb-2">
                            {data?.detail?.base_distance_cost}
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-28 text-xl text-gray-100 bg-green-900 pb-2">
                            Distance price
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {data?.detail?.distance_cost > 0
                              ? data?.detail?.distance_cost
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/pin.svg"
                              alt="data"
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400 pl-4 text-xl">
                                TRIP ID
                              </span>
                              <br />
                              <span className="pl-4 text-gray-100 text-xl">
                                {data?.detail?.unique_id}
                              </span>
                            </div>
                          </td>
                          <td className="pl-28 text-xl text-gray-100 bg-green-500 pb-2">
                            Time Price
                          </td>

                          <td className="pl-10 text-xl text-gray-100 bg-green-500 pb-2">
                            {data?.detail?.time_cost}
                          </td>

                          {/* <td className="pl-10 text-xl text-gray-100 bg-green-500 pb-2">
                            {data?.detail?.promo_payment > 0
                              ? data?.detail?.promo_payment
                              : "-"}
                          </td> */}
                        </tr>
                        <tr>
                          <td className="pl-28 text-xl text-gray-100 bg-green-900 pb-2">
                            surcharges
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {data?.detail?.surge_fee > 0
                              ? data?.detail?.surge_fee
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/vehicle-number.svg"
                              alt=""
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400 pl-4 text-xl">
                                Vehicle number
                              </span>
                              <br />
                              <span className="pl-4 text-gray-100 text-xl">
                                {
                                  data?.provider_detail?.vehicle_detail?.[0]
                                    .plate_no
                                }
                              </span>
                            </div>
                          </td>
                          <td className="pl-28 text-xl text-gray-100 bg-green-500 pb-2">
                            Taxes
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-500 pb-2">
                            {data?.detail?.tax_fee > 0
                              ? data?.detail?.tax_fee
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-28 text-xl text-gray-100 bg-green-900 pb-2">
                            Toll/Parking charges
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {data?.detail?.is_toll === true
                              ? data.detail.toll_amount
                              : 0}
                          </td>
                        </tr>

                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/vehicle-type.svg"
                              alt=""
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400  text-xl">
                                Vehicle type
                              </span>
                              <br />
                              <span className="text-gray-100 text-xl">
                                {data?.service_detail?.typename}
                              </span>
                            </div>
                          </td>
                          <td className=" pl-28 text-xl text-gray-100 bg-green-500 ">
                            Total Amount
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-500 pb-2">
                            {data?.detail?.total_after_tax_fees}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-28 text-xl text-gray-100 bg-green-900 pb-2">
                            First Trip Discount :
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {data?.detail?.userFirstTrip === true ? 100 : 0}
                          </td>
                        </tr>

                        {/* <tr>
                          <td className="pl-28 text-xl text-gray-100 bg-green-900 pb-2">
                            Promo Discount
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {data?.detail?.promo_payment}
                          </td>
                        </tr> */}

                        <tr>
                          <td rowSpan={2} className="bg-black w-40">
                            <img
                              src="assets/mobile.svg"
                              alt="Mobile"
                              className="p-4"
                            />
                          </td>
                          <td rowSpan={2} className="bg-black w-40">
                            <div>
                              <span className="text-gray-400 text-xl ">
                                Customer Mobile
                              </span>
                              <br />
                              <span className=" text-gray-100 text-xl">
                                {data?.user_detail?.phone}
                              </span>
                            </div>
                          </td>
                          <td className="pl-28 text-xl text-gray-100 bg-green-500 pb-2">
                            Airport parking charges
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-500 pb-2">
                            {data?.detail?.airport_add_charge
                              ? data?.detail?.airport_add_charge
                              : "-"}
                          </td>
                        </tr>
                        <tr className=" ">
                          <td className="pl-28 text-xl text-gray-100 bg-green-900 pb-2 ">
                            Total amount
                          </td>
                          <td className="pl-10 text-xl text-gray-100 bg-green-900 pb-2">
                            {data?.detail?.total}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </>
              )}
              <>
                <div className="flex  justify-between px-10">
                  <div>
                    <p className=" pt-8 text-xl">Trip date</p>
                    <p className=" text-2xl">
                      {data?.detail?.complete_date_tag}
                    </p>
                  </div>

                  <div>
                    <p className=" pt-8 text-xl">Time</p>
                    <p className=" text-2xl">{data?.detail?.total_time} min</p>
                  </div>

                  <div>
                    <p className=" pt-8 text-xl">Distance</p>
                    <p className=" text-2xl">
                      {data?.detail?.total_distance} &nbsp;
                      {data?.detail?.unit === 1 ? "Km" : "Miles"}
                    </p>
                  </div>
                  <div>
                    <p className="pt-8 text-xl">Fare payable</p>
                    {data?.detail?.corporate_id !== null ? (
                      <p className="text-2xl">0 </p>
                    ) : (
                      <p className="text-2xl"> &#8377; {data?.detail?.total}</p>
                    )}
                  </div>
                </div>
              </>
              <div>
                <div className="p-10 w-full">
                  <img src="assets/ad.svg " alt="ad" className="w-full" />
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="pt-2 pl-8 ">Thankyou for riding with OHM</p>
                  <p className="pl-8">
                    Please take a moment to give us your valuable feedback
                  </p>
                </div>
                <div className="pr-10">
                  <p className="text-gray-500 flex justify-center">
                    800g CO2 saved with this ride
                  </p>
                  <p className="text-gray-500 flex justify-center">
                    1200+ Tonnes CO2 Saved Till October
                  </p>
                </div>
              </div>
              <div className="pt-20">
                {/* <button class="bg-black text-white font-semibold text-4xl py-2 px-4 border border-gray-400 w-60 h-16" >
               Download
            </button> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >

              <GenericPdfDownloader
                downloadFileName={invoiceString}
                rootElementId="invoice"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
